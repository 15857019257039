<template>
  <div class="flex relative">
    <left-bar/>
    <div class="content w-full mx-12 main-content">
      <FeatureNav />
      <div class="flex flex-wrap">
        <template>
          <div class="w-1/5 px-4 pt-64">
            <ul class="border-t border-grey-darker-200">
              <li 
                v-for="link in linksList"
                :key="link.name"
                class="border-b border-dashed"
              >
                <router-link 
                  :to="link.link"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  {{ link.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="w-4/5"> 
            <router-view />
          </div>
        </template>
      </div>
    </div>
    <ProfileNav />
  </div>
</template>

<script>
  import FeatureNav from '@/components/FeatureNav';
  import ProfileNav from '@/components/ProfileNav'
  import LeftBar from '@/components/LeftBar.vue';
  import { mapGetters } from 'vuex'

  export default {
    components: {
      FeatureNav, 
      ProfileNav, 
      LeftBar
    },
    data(){
      return{
        linksList: [
          {
            name: 'Account',
            link: '/settings/account'
          },
          {
            name: 'Search',
            link: '/settings'
          },
          // {
          //   name: 'Billing & Plans',
          //   link: '/settings/billing-plans'
          // },
          // {
          //   name: 'API',
          //   link: '/settings/api'
          // },
          // {
          //   name: 'Labels',
          //   link: '/settings/labels'
          // },
          {
            name: 'Team',
            link: '/settings/team'
          },
        ]
      }
    },
    computed: {
      ...mapGetters({
        userAppData: 'general/userAppData'
      })
    },
    mounted(){
    }
  }
</script>

<style lang="scss" scoped>
.router-link-exact-active{
  color: #202020;
}
</style> 